import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';
import { Flex, Text, Box, Image, Heading } from '@chakra-ui/react';
import Button from '../components/button';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { AppContext } from '../context/index';
import gif from '../../static/new_images/aavolutionhouse.gif';

// eslint-disable-next-line no-unused-vars
const SDKPage = ({ intl }) => (
  <AppContext.Consumer>
    {() => (
      <Layout>
        <SEO path="aavolutionhouse" />
        <Flex
          width="100%"
          px={{ base: '24px', md: '64px', lg: '120px' }}
          pt={{ base: '86px', md: '88px', lg: '112px' }}
          pb={{ base: '72px', md: '104px', lg: '40px' }}
        >
          <Box
            alignItems={{ base: 'flex-end', lg: 'center' }}
            width="100%"
            maxWidth="1200px"
            margin="0 auto"
            textAlign="center"
            color="icon.text"
          >
            <Heading mt="space.5xl" mb="space.xl" color="icon.text">
              🎊We are glad to meet you in Istanbul and thank you for attending the event 🎊
            </Heading>
            <Image src={gif} margin="0 auto" />
            <Text fontSize="size.body.1" my="space.xl">
              Now, it's time to turn the excitement up a notch—post this tweet and grab the
              spotlight with your exclusive Blocto merch 🎁
            </Text>
            <Button
              mb="space.3xl"
              onClick={() =>
                window.open(
                  'https://twitter.com/intent/tweet?text=Having%20a%20blast%20at%20%23AAvolution%20House%20Istanbul%20with%20%40BloctoApp%20and%20frens!%20Enjoying%20every%20moment%20of%20learning%20more%20about%20%23AccountAbstraction!%F0%9F%9A%80',
                  '_blank'
                )
              }
            >
              Post to Claim
            </Button>
          </Box>
        </Flex>
      </Layout>
    )}
  </AppContext.Consumer>
);

SDKPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object.isRequired,
};

export default injectIntl(SDKPage);
